<script setup lang="ts">
import { DiscountCodeProps } from "./DiscountCode.props"
import { NotificationAlertProps } from "~/components/NotificationAlert.props"

const props = withDefaults(defineProps<DiscountCodeProps>(), {
  status: "default"
})

defineEmits<{
  (e: "onClickCTA"): void
}>()

const { copy } = useClipboard()
const { addNotification } = useNotification()
const { t } = useI18n()

// So we dont expose the code
const visibleDiscountCode = computed(() =>
  props.status === "default" && props.discountCode
    ? `${props.discountCode.slice(-2)}`
    : `${props.discountCode}`
)

const handleCopyToClipboard = () => {
  copy(props.discountCode)
  const notification: NotificationAlertProps = {
    id: Math.random(),
    type: "success",
    notificationAlertText: t("discounts.copyCodeToClipboard"),
    isWithCloseIcon: true
  }

  addNotification(notification)
}

const stringWithoutEuroSymbol = computed(() =>
  props.discount?.toString().replace("€", "")
)

const splittedPrice = computed(() => {
  if (props.symbol === "€") {
    return stringWithoutEuroSymbol.value.split(",")
  } else {
    return ""
  }
})

const integersPart = computed(() => splittedPrice.value.at(0))
const decimalsPart = computed(() => splittedPrice.value.at(1))
</script>

<template>
  <div
    class="card-discount_code flex flex-col space-y-4 p-4 md:p-0"
    :class="{
      'flex-wrap items-center justify-between gap-4 !space-y-0 p-6 md:flex-row':
        isBig
    }"
  >
    <div class="flex items-center gap-4" :class="{ 'flex-1': isBig }">
      <div class="relative inline-block justify-self-start">
        <span
          class="
            absolute
            left-1/2
            top-1/2
            flex
            -translate-x-1/2 -translate-y-1/2
            items-baseline
            font-Poppins font-medium
            leading-[44.8px]
          "
          :class="[
            status === 'default' ? 'text-white' : 'text-black-main',
            { 'line-through	': status === 'used' }
          ]"
        >
          <span :class="symbol === '%' ? 'mammoth' : 'hyppo'">
            <span v-if="symbol === '%'">
              {{ discount }}
            </span>
            <span v-else class="flex items-baseline">
              <span> {{ integersPart }} </span>
              <span class="mouse-bold">,{{ decimalsPart }} </span>
            </span>
          </span>
          <span
            class="pl-[2px]"
            :class="symbol === '%' ? 'pig-bold' : 'mouse-bold'"
          >
            {{ symbol }}
          </span>
        </span>
        <IconsDiscountRibbon
          class="h-[88px] w-[88px]"
          :class="{
            'text-green-main': status === 'default',
            'text-tiffany-60':
              status === 'ready' ||
              status === 'applied' ||
              status === 'applying',
            'text-slate-20': status === 'used'
          }"
        />
      </div>
      <div class="mouse space-y-4" :class="{ '!space-y-2': isBig }">
        <!-- Info -->
        <UtilsMarkdown
          :content="
            status === 'default'
              ? $t(`discounts.${status}.info${stringWithoutEuroSymbol}`)
              : $t(`discounts.${status}.info`, {
                  n: stringWithoutEuroSymbol,
                  symbol: symbol,
                  info: symbol === '€' ? 'di' : 'del'
                })
          "
        />

        <p
          v-if="status !== 'used'"
          class="mouse"
          :class="[isBig ? 'block' : 'hidden']"
        >
          {{ $t("discounts.notCumulative") }}
        </p>
      </div>
    </div>
    <div class="flex flex-col gap-4" :class="{ 'flex-1': isBig }">
      <p
        v-if="status !== 'used'"
        class="mouse"
        :class="[isBig ? 'hidden' : 'block']"
      >
        {{ $t("discounts.notCumulative") }}
      </p>
      <div class="mouse-medium md:beaver-medium flex items-center gap-1">
        <IconsCheckmark
          v-if="status === 'applied'"
          class="h-6 w-6 text-positive-100"
        />
        <IconsClose
          v-if="status === 'used'"
          class="h-6 w-6 text-strawberry-main"
        />
        <p
          class="text-green-main"
          :class="{
            'text-positive-100': status === 'applied',
            'text-strawberry-main': status === 'used'
          }"
        >
          {{
            symbol === "%" && status === "default"
              ? $t(`discounts.${status}.instructions${ctaFunction}`)
              : $t(`discounts.${status}.instructions`)
          }}
        </p>
      </div>
      <div
        class="
          !pig-medium
          flex flex-col
          space-y-4
          md:items-center
          md:justify-between
          md:gap-4
          md:space-y-0
        "
        :class="isBig ? 'md:flex-row' : 'flex-col'"
      >
        <!-- CTA 1 -->
        <div class="relative w-full">
          <div class="relative overflow-hidden rounded-lg">
            <UtilsButton
              :text="`${$t(
                'discounts.discountCode'
              )}: <strong>${visibleDiscountCode}</strong>`"
              fluid
              theme="outlined-tiffany-60"
              order="last"
              :is-disabled="status === 'used'"
              :class="[
                {
                  'pointer-events-none select-none\t [&>span]:flex [&>span]:justify-end\t':
                    status === 'default' || status === 'used'
                },
                { '[&>*]:whitespace-normal': status === 'used' }
              ]"
              @click="handleCopyToClipboard"
            >
              <template #icon
                ><UtilsIcon
                  decoding="async"
                  fetch-priority="low"
                  name="Copy.svg"
                  color="green-main"
                  class="h-5 w-5"
              /></template>
            </UtilsButton>
            <div
              v-if="status === 'default' || status === 'used'"
              class="absolute inset-0 right-10 flex items-center justify-center"
              :class="[
                status === 'default'
                  ? 'bg-tiffany-400 text-white'
                  : 'bg-slate-20 text-slate-80'
              ]"
            >
              {{ $t("discounts.discountCode") }}
            </div>
          </div>
          <IconsFoldedLip
            v-if="status === 'default' || status === 'used'"
            class="absolute right-[25px] top-0"
            :class="[status === 'default' ? 'text-green-70' : 'text-slate-40']"
          />
        </div>
        <!-- CTA 2 -->
        <UtilsButton
          :key="visibleDiscountCode + status"
          class="w-full self-start md:w-60"
          :class="{
            '[&>span]:!bg-positive-main [&>span]:!text-white':
              status === 'applied',
            'shrink-0': isBig
          }"
          :text="
            status === 'default'
              ? $t(`discounts.${status}.ctaText${ctaFunction}`)
              : $t(`discounts.${status}.ctaText`)
          "
          order="last"
          :is-disabled="status === 'used' || status === 'applied'"
          @click="$emit('onClickCTA')"
        >
          <template #icon>
            <UtilsLoader v-if="status === 'applying'" />
            <IconsCheckmark
              v-if="status === 'used' || status === 'applied'"
              class="h-5 w-5"
            />
          </template>
        </UtilsButton>
      </div>
      <UtilsMarkdown
        v-if="$te(`discounts.${status}.label${ctaFunction}`)"
        class="mouse markdown__link-underlined mb-4"
        :content="$t(`discounts.${status}.label${ctaFunction}`)"
      />
    </div>
  </div>
</template>
